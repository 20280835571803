import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import {
  getClients,
  createSubpoena,
  getDidDetail,
  getSubpoena,
  getSubpoenaDetail,
  getCSVData,
  getContractPdf,
} from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import MutextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { saveAs } from "file-saver";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import Box from "@material-ui/core/Box";

const auth = new HelperClass();

const styles = {
  sectionss: {
    marginTop: "0px",
  },
};

/*const downloadCSV = async (url, name) => {
  const response = await fetch(url);
  const data = await response.text();
  const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
  const blobURL = window.URL.createObjectURL(blob);

  // Create new tag for download file
  const anchor = document.createElement("a");
  anchor.download = name;
  anchor.href = blobURL;
  anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
    ":"
  );
  anchor.click();

  // Remove URL.createObjectURL. The browser should not save the reference to the file.
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobURL);
  }, 100);
};*/

export default class AdminSubpoena extends Component {
  constructor() {
    super();
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);

    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      reseller_accountno: "",
      clientData: [],
      list_users: [],
      disabled: false,
      disabled_final: false,
      errorMessage: "",
      successMessage: "",
      errorMessaged: "",
      successMessaged: "",
      request_did: "",
      service_type: "did",
      start_date: datestring_today,
      end_date: datestring,
      subscriber_settings: false,
      billing_settings: false,
      other_did_settings: false,
      call_log_settings: false,
      contract_settings: false,
      client_comm_settings: false,
      payment_settings: false,
      send_email: false,
      email_address: "",
      first_result: "",
      did_data: [],
      ref_number: "",
      did_companies: "",
      subscriber_popup: "",
      billing_popup: "",
      payment_popup: "",
      contracts_popup: "",
      contractName: "",
      privacy_popup: "",
      terms_popup: "",
      cilent_comm_popup: "",
      did_csvs: "",
      call_logs: "",
      agreement_view: "",
      delete_id: "",
      columns: [
        { name: "Ref #" },
        { name: "Date Created" },
        { name: "DID" },
        { name: "From" },
        { name: "To" },
        { name: "Reseller" },
        {
          name: "Subpoena Detail",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {" "}
                  <a
                    href="#"
                    onClick={() => this.modelDetails(value, tableMeta)}
                    style={{ textAlign: "left" }}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    VIEW DETAILS
                  </a>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    const clientsResponce = await getSubpoena(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    //console.log("SUBPOENA : ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (clientsResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({ tableData: clientsResponce.data.data, errorMessage: "" });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    }
  }

  modelDetails = async (value, tableMeta) => {
    this.setState({
      subscriber_popup: "",
      billing_popup: "",
      payment_popup: "",
      privacy_popup: "",
      terms_popup: "",
      cilent_comm_popup: "",
      did_csvs: "",
      call_logs: "",
    });
    let subpoena_id = tableMeta.rowData[6];
    //console.log("CSV ID: ", subpoena_id);
    const clientsResponce = await getSubpoenaDetail(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      subpoena_id
    );
    //console.log("DID SCV: ", clientsResponce.data);
    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (clientsResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      let subscriber_popup = this.renderData(clientsResponce.data.data[0][5]);
      let billing_popup = this.renderData(clientsResponce.data.data[0][6]);
      let payment_popup = this.renderData(clientsResponce.data.data[0][9]);
      let privacy_popup = clientsResponce.data.data[0][10];
      let terms_popup = clientsResponce.data.data[0][11];
      let cilent_comm_popup = this.renderData(clientsResponce.data.data[0][8]);

      let did_csvs = this.viewDownloadBtns(
        value,
        clientsResponce.data.data[0][13]
      );

      let call_logs = this.viewLogsBtns(
        value,
        clientsResponce.data.data[0][14]
      );

      this.setState({
        subscriber_popup: subscriber_popup,
        billing_popup: billing_popup,
        payment_popup: payment_popup,
        privacy_popup: privacy_popup,
        terms_popup: terms_popup,
        cilent_comm_popup: cilent_comm_popup,
        did_csvs: did_csvs,
        call_logs: call_logs,
      });
      window.$("#modalDetails").modal("show");
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    }
  };

  renderData = (data) => {
    //return JSX that renders action buttons...
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };

  viewDownloadBtns = (value, tableMeta) => {
    if (tableMeta.includes("###")) {
      let download_array = tableMeta.split("###");
      let returned_file = download_array[1];
      let purchased_file = download_array[0];

      if (returned_file !== "" || purchased_file !== "") {
        return (
          <div>
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{purchased_file}</td>
                  <td>
                    <Link
                      onClick={() => this.downloadCsv(purchased_file)}
                      title="Purchased File"
                    >
                      <GetAppOutlinedIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>{returned_file}</td>
                  <td>
                    <Link
                      onClick={() => this.downloadCsv(returned_file)}
                      title="Returned File"
                    >
                      <GetAppOutlinedIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        return "";
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: tableMeta }} />;
    }
  };

  viewLogsBtns = (value, tableMeta) => {
    if (tableMeta.includes(".csv")) {
      let log_file = tableMeta;

      if (log_file !== "") {
        return (
          <div>
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Call Logs</td>
                  <td>
                    <Link
                      onClick={() => this.downloadCsv(log_file)}
                      title="Call Logs CSV File"
                    >
                      <GetAppOutlinedIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      } else {
        return "";
      }
    } else {
      return <div dangerouslySetInnerHTML={{ __html: tableMeta }} />;
    }
  };

  clientsView = async () => {
    const loadSettings = await getClients(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    //console.log("loadSettings.data.data: ", loadSettings.data.data);
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      this.setState({
        clientData: loadSettings.data.data,
        reseller_accountno: loadSettings.data.data[0][1],
      });
    }
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  statusText = (value, tableMeta) => {
    let is_status = tableMeta.rowData[4];
    if (is_status === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  signupText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[5];
    if (is_signup === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[7];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  assignHtml = (value) => {
    if (value) {
      return "";
    } else {
      return "";
    }
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  downloadCsv = async (page_id) => {
    const reportsData = await getCSVData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      page_id
    );
    //console.log("reportsData.data.data: ", reportsData.data.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      const response = await fetch(url);
      const data = await response.text();
      const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = page_id;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);

      //setTimeout(() => {
      //window.location.replace("/admin_subpoena");
      //}, 2000);
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  downloadPdf = async (d_f_name) => {
    const reportsData = await getContractPdf(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      d_f_name
    );
    //console.log("PDF.data: ", reportsData.data.data + " " + d_f_name);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = d_f_name;
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  };

  formGetDIDDetail = async () => {
    let { request_did, reseller_accountno, start_date, end_date, ref_number } =
      this.state;

    this.setState({
      did_data: [],
      errorMessage: "",
      first_result: "",
      disabled: true,
    });
    let is_validated = true;
    if (request_did === "") {
      is_validated = false;
    }
    if (reseller_accountno === "") {
      is_validated = false;
    }
    if (start_date === "") {
      is_validated = false;
    }
    if (end_date === "") {
      is_validated = false;
    }
    if (is_validated) {
      const getDidDetails = await getDidDetail(
        auth.getAdminAccount(),
        auth.getAdminToken(),
        this.state.reseller_accountno,
        this.state.request_did,
        this.state.start_date,
        this.state.end_date,
        ref_number
      );
      //console.log("DID LOG  ", getDidDetails.data.data);
      if (
        getDidDetails.data.status === 403 ||
        getDidDetails.data.errors === "authentication missing" ||
        getDidDetails.data.errors === "jwt expired"
      ) {
        auth.adminlogout();
      } else if (getDidDetails.data.status === 404) {
        this.setState({
          errorMessage: "Data not found against this DID.",
          disabled: false,
        });
      } else if (
        getDidDetails.data.status === 200 &&
        getDidDetails.data.message === "success"
      ) {
        if (getDidDetails.data.data) {
          let user_array = [];
          var uniqueNames = [];
          var did_companies_arr = "";
          if (getDidDetails.data.data.length > 0) {
            if (getDidDetails.data.data[0][6]) {
              console.log(
                "USER ARRAY INITIAL  ",
                getDidDetails.data.data[0][6]
              );
              user_array = getDidDetails.data.data[0][6].split("|||");
              /*getDidDetails.data.data[0][6].forEach(function (item) {
              if (user_array.indexOf(item) === -1) {
                user_array.push(item);
              }
            });*/

              $.each(user_array, function (i, el) {
                if (el !== "") {
                  if ($.inArray(el, uniqueNames) === -1) {
                    uniqueNames.push(el);
                    var splitArr = el.split("###");
                    did_companies_arr += splitArr[0] + ",";
                  }
                }
              });
            }
          }
          //console.log("USER ARRAY  ", uniqueNames);
          if (getDidDetails.data.data.length > 0) {
            this.setState({
              did_data: getDidDetails.data.data,
              list_users: uniqueNames,
              did_companies: did_companies_arr,
              errorMessage: "",
              first_result: "1",
              disabled: false,
            });
          } else {
            this.setState({
              errorMessage: "Data not found against this DID.",
              disabled: false,
            });
          }
        } else {
          this.setState({
            errorMessage: "Data not found against this DID.",
            disabled: false,
          });
        }
      } else {
        this.setState({
          errorMessage: "There is some error while processing your request.",
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please enter required fields.",
        disabled: false,
      });
    }
  };

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  saveSubpoena = async () => {
    let {
      request_did,
      reseller_accountno,
      start_date,
      end_date,
      ref_number,
      subscriber_settings,
      billing_settings,
      other_did_settings,
      contract_settings,
      client_comm_settings,
      payment_settings,
      notes,
      did_companies,
      call_log_settings,
    } = this.state;
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    subscriber_settings = "0";
    if (this.state.subscriber_settings === true) {
      subscriber_settings = "1";
    }
    billing_settings = "0";
    if (this.state.billing_settings === true) {
      billing_settings = "1";
    }
    other_did_settings = "0";
    if (this.state.other_did_settings === true) {
      other_did_settings = "1";
    }
    contract_settings = "0";
    if (this.state.contract_settings === true) {
      contract_settings = "1";
    }
    client_comm_settings = "0";
    if (this.state.client_comm_settings === true) {
      client_comm_settings = "1";
    }
    payment_settings = "0";
    if (this.state.payment_settings === true) {
      payment_settings = "1";
    }

    call_log_settings = "0";
    if (this.state.call_log_settings === true) {
      call_log_settings = "1";
    }
    /*console.log("this.state.request_did: ", this.state.request_did);
    console.log("this.state.start_date: ", this.state.start_date);
    console.log("this.state.end_date: ", this.state.end_date);
    console.log("ref_number: ", ref_number);
    console.log("subscriber_settings: ", subscriber_settings);
    console.log("billing_settings: ", billing_settings);
    console.log("other_did_settings: ", other_did_settings);
    console.log("contract_settings: ", contract_settings);
    console.log("client_comm_settings: ", client_comm_settings);
    console.log("payment_settings: ", payment_settings);
    console.log("notes: ", notes);
    console.log("did_companies: ", did_companies);*/

    const getDidDetails = await createSubpoena(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      this.state.request_did,
      this.state.start_date,
      this.state.end_date,
      ref_number,
      subscriber_settings,
      billing_settings,
      other_did_settings,
      contract_settings,
      client_comm_settings,
      payment_settings,
      notes,
      did_companies,
      call_log_settings
    );
    //console.log("Subpoena.data: ", getDidDetails.data);
    window.$("#modalDetails").scrollTop(0);
    if (
      getDidDetails.data.status === 403 ||
      getDidDetails.data.errors === "authentication missing" ||
      getDidDetails.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (getDidDetails.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while processing your request.",
        successMessage: "",
        disabled_final: false,
      });
    } else if (
      getDidDetails.data.status === 200 &&
      getDidDetails.data.message === "success"
    ) {
      if (getDidDetails.data.data) {
        this.setState({
          errorMessage: "",
          did_data: [],
          successMessage:
            "Your process has been started. CSV file of requested DIDs may take some time.",
          disabled_final: false,
        });
        setTimeout(function () {
          window.location.reload(1);
        }, 3000);
      } else {
        this.setState({
          errorMessage: "Data not found against this DID.",
          successMessage: "",
          disabled_final: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "There is some error while processing your request.",
        successMessage: "",
        disabled_final: false,
      });
    }
  };

  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  modalAddSubpoena = async () => {
    //modalAddForm

    await this.clientsView();
    window.$("#modalAddForm").modal("show");
  };

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  parseCompanyString = (str) => {
    var pattern = str.split("###");
    return pattern[1];
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };
  render() {
    $("#useraccountno").hide();
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* SETTINGS MODAL START */}

          <div className="modal fade" tabIndex="-1" id="modalAddForm">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Create New Subpoena Request</h5>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* Error Start */}
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <br />
                  <div className="row g-2">
                    <div className="col-lg-12 col-xxl-12">
                      <div className="form-group">
                        <MutextField
                          id="reseller_accountno"
                          name="reseller_accountno"
                          select
                          label="Select Reseller"
                          value={this.state.reseller_accountno}
                          onChange={this.setMarkUp}
                          SelectProps={{
                            native: true,
                          }}
                          fullWidth
                        >
                          {this.state.clientData.map((clients) => (
                            <option value={clients[1]}>
                              {clients[4]} - {clients[2]}
                            </option>
                          ))}
                        </MutextField>
                      </div>
                    </div>
                  </div>
                  <div className="row g-4 ">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <MutextField
                          required
                          id="ref_number"
                          name="ref_number"
                          type="text"
                          label="Reference Number "
                          placeholder="55555"
                          inputProps={{ maxLength: 20 }}
                          defaultValue={this.state.ref_number}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <MutextField
                          required
                          id="request_did"
                          name="request_did"
                          inputProps={{ maxLength: 11 }}
                          type="text"
                          label="Enter DID"
                          placeholder="Ex: 5555555555 *"
                          defaultValue={this.state.request_did}
                          onChange={this.handleChange}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="start_date"
                            name="start_date"
                            format="yyyy-MM-dd"
                            margin="normal"
                            label="Select Start Date *"
                            value={this.state.start_date}
                            onChange={this.handleDateChange}
                            style={styles.sectionss}
                            disableEnforceFocus
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                              variant: "outlined",
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="end_date"
                            name="end_date"
                            format="yyyy-MM-dd"
                            margin="normal"
                            label="Select End Date *"
                            value={this.state.end_date}
                            onChange={this.handleDateChangeEnd}
                            disableEnforceFocus
                            style={styles.sectionss}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="submit"
                          className="btn btn-primary btn-lg btn-group btn-action"
                          style={{
                            color: THEME_TEXT_COLOR,
                            float: "right",
                            marginTop: "15px",
                          }}
                          disabled={this.state.disabled}
                          value="Submit Subpoena Request"
                          onClick={() => this.formGetDIDDetail()}
                        />
                      </div>
                    </div>
                    <Divider />
                    {this.state.first_result !== "" ? (
                      <div
                        className="col-lg-12 border-top"
                        style={{ marginTop: "20px" }}
                      >
                        {/*<h5>Review Number History ({this.state.ref_number})</h5>*/}
                        <div id="accordion" class="accordion  accordion-s3">
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head"
                              data-toggle="collapse"
                              data-target="#accordion-item-1"
                            >
                              <h6 class="title">
                                Review Number History ({this.state.ref_number})
                              </h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse show"
                              id="accordion-item-1"
                              data-parent="#accordion"
                            >
                              <div class="accordion-inner">
                                <div className="table-responsive">
                                  <table
                                    className="table table-sm"
                                    style={{ fontSize: "0.70rem" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Old User</th>
                                        <th scope="col">New User</th>
                                        <th scope="col">
                                          Old Responsible Party
                                        </th>
                                        <th scope="col">
                                          New Responsible Party
                                        </th>
                                        <th scope="col">REASON</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.did_data.map((dids) => (
                                        <tr>
                                          <td>{dids[0]}</td>
                                          <td>{dids[1]}</td>
                                          <td>{dids[2]}</td>
                                          <td>{dids[3]}</td>
                                          <td>{dids[4]}</td>
                                          <td>{dids[5]}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {this.state.list_users.length > 0 ? (
                      <div className="col-lg-12 col-md-12 col-xl-12">
                        <div
                          className="col-lg-12 col-md-12 col-xl-12  border-top"
                          style={{ marginTop: "20px" }}
                        >
                          <h6 style={{ marginTop: "20px" }}>DID Customers </h6>
                        </div>
                        {this.state.list_users.map((users) => (
                          <div className="col-lg-4 col-md-4 col-xl-4">
                            {this.parseCompanyString(users)}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <div className="row g-2">
                      <div
                        className="col-lg-12 col-md-12 col-xl-12  border-top"
                        style={{ marginTop: "20px" }}
                      >
                        <h5 style={{ marginTop: "20px" }}>Choose Options </h5>
                      </div>

                      <div className="col-lg-6 col-md-6 col-xl-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.subscriber_settings}
                              onChange={this.handleChange}
                              name="subscriber_settings"
                              id="subscriber_settings"
                              color="primary"
                            />
                          }
                          label="Request Subscriber Details"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-xl-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.billing_settings}
                              onChange={this.handleChange}
                              name="billing_settings"
                              id="billing_settings"
                              color="primary"
                            />
                          }
                          label="Request Billing Details"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-xl-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.other_did_settings}
                              onChange={this.handleChange}
                              name="other_did_settings"
                              id="other_did_settings"
                              color="primary"
                            />
                          }
                          label="Request All Numbers (Includes Returned)"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-xl-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.contract_settings}
                              onChange={this.handleChange}
                              name="contract_settings"
                              id="contract_settings"
                              color="primary"
                            />
                          }
                          label="Request Contracts"
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.client_comm_settings}
                              onChange={this.handleChange}
                              name="client_comm_settings"
                              id="client_comm_settings"
                              color="primary"
                            />
                          }
                          label="Request Client Communication"
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.payment_settings}
                              onChange={this.handleChange}
                              name="payment_settings"
                              id="payment_settings"
                              color="primary"
                            />
                          }
                          label="Request Payment Info"
                        />
                      </div>

                      <div className="col-lg-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.call_log_settings}
                              onChange={this.handleChange}
                              name="call_log_settings"
                              id="call_log_settings"
                              color="primary"
                            />
                          }
                          label="Request Call Logs"
                        />
                      </div>
                      {/*<div className="col-lg-6">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.send_email}
                              onChange={this.handleChange}
                              name="send_email"
                              id="send_email"
                              color="primary"
                            />
                          }
                          label="Send Email"
                        />
                      </div>
                       {this.state.send_email === true ? (
                        <>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <MutextField
                                id="email_address"
                                name="email_address"
                                type="email"
                                label="Enter Email Address"
                                placeholder="Ex: aaa@example.com"
                                defaultValue={this.state.email_address}
                                onChange={this.handleChange}
                                fullWidth
                              />
                            </div>
                          </div>
                        </>
                     ) : null}*/}
                    </div>
                    <div className="col-lg-12">
                      <MutextField
                        id="notes"
                        name="notes"
                        type="email"
                        label="Notes"
                        placeholder="Enter notes here"
                        defaultValue={this.state.notes}
                        onChange={this.handleChange}
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </div>
                    {this.state.first_result !== "" ? (
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="submit"
                              className="btn btn-primary btn-lg btn-group btn-action"
                              style={{
                                color: THEME_TEXT_COLOR,
                                marginTop: "15px",
                              }}
                              disabled={this.state.disabled_final}
                              value="Submit"
                              onClick={() => this.saveSubpoena()}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="submit"
                              className="btn btn-primary btn-lg btn-group btn-action"
                              style={{
                                color: THEME_TEXT_COLOR,
                                marginTop: "15px",
                              }}
                              disabled="disabled"
                              value="Submit"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="form-group"></div>

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* END MODAL ADD SERVICE */}
          {/* DETAIL MODAL START */}
          <div className="modal fade" tabIndex="-1" id="modalSubscriber">
            <div
              className="modal-dialog modal-lg"
              style={{ padding: "25px !important" }}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em class="icon ni ni-card-view"></em> Subscriber Details
                      <sup>
                        <small class="text-soft"></small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  <div className="nk-modal">
                    <div className="nk-modal-text">
                      <div className="caption-text">
                        {this.state.subscriber_popup !== ""
                          ? this.state.subscriber_popup
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL MODAL End */}
          {/* DETAIL MODAL START */}
          <div className="modal fade" tabIndex="-1" id="modalBilling">
            <div
              className="modal-dialog modal-lg"
              style={{ padding: "2rem" }}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em class="icon ni ni-card-view"></em> Billing Details
                      <sup>
                        <small class="text-soft"></small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  {this.state.billing_popup !== "" ? (
                    <>{this.state.billing_popup}</>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL MODAL End */}
          {/* DETAIL MODAL START */}
          <div className="modal fade" tabIndex="-1" id="modalPayments">
            <div
              className="modal-dialog modal-lg"
              style={{ padding: "2rem" }}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em class="icon ni ni-card-view"></em> Payment Details
                      <sup>
                        <small class="text-soft"></small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  {this.state.payment_popup !== "" ? (
                    <>{this.state.payment_popup}</>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL MODAL End */}
          {/* DETAIL MODAL START */}
          <div className="modal fade" tabIndex="-1" id="modalContracts">
            <div
              className="modal-dialog modal-lg"
              style={{ paddingTop: "15px" }}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em class="icon ni ni-card-view"></em>{" "}
                      {this.state.contractName}
                      <sup>
                        <small class="text-soft"></small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  {this.state.contracts_popup !== "" ? (
                    <>{this.state.contracts_popup}</>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL MODAL End */}

          {/* DETAIL MODAL START */}

          <div className="modal fade" tabIndex="-1" id="modalDetails">
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              style={{ paddingTop: "15px", maxWidth: "80%" }}
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em class="icon ni ni-card-view"></em> Subpoena Details
                      <sup>
                        <small class="text-soft"></small>
                      </sup>
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body text-left">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#tabItem1"
                      >
                        <em class="icon ni ni-user-list"></em>
                        <span>Subscriber Details</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem2">
                        <em class="icon ni ni-account-setting"></em>
                        <span>Biling Details</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem3">
                        <em class="icon ni ni-download"></em>
                        <span>Other DID CSV's</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem4">
                        <em class="icon ni ni-book-read"></em>
                        <span>Contracts</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem5">
                        <em class="icon ni ni-list-round"></em>
                        <span>Call Logs</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem6">
                        <em class="icon ni ni-user"></em>
                        <span>Client Communications</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem7">
                        <em class="icon ni ni-cc-alt"></em>
                        <span>Payment Details</span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tabItem1">
                      {this.state.subscriber_popup !== "" ? (
                        <>{this.state.subscriber_popup}</>
                      ) : null}
                    </div>
                    <div class="tab-pane" id="tabItem2">
                      {this.state.billing_popup !== "" ? (
                        <>{this.state.billing_popup}</>
                      ) : null}
                    </div>
                    <div class="tab-pane" id="tabItem3">
                      {this.state.did_csvs !== "" ? (
                        <>{this.state.did_csvs}</>
                      ) : null}
                    </div>
                    <div class="tab-pane" id="tabItem4">
                      <table class="table">
                        <thead class="thead-light">
                          <tr>
                            <th scope="col">File Name</th>
                            <th scope="col">Download</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.privacy_popup !== "" ? (
                            <>
                              <tr>
                                <td>Privacy Policy</td>
                                <td>
                                  <Link
                                    onClick={() =>
                                      this.downloadPdf(this.state.privacy_popup)
                                    }
                                    title="Privacy Policy"
                                  >
                                    <GetAppOutlinedIcon
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            </>
                          ) : null}
                          {this.state.terms_popup !== "" ? (
                            <>
                              <tr>
                                <td>Terms and Conditions</td>
                                <td>
                                  <Link
                                    onClick={() =>
                                      this.downloadPdf(this.state.terms_popup)
                                    }
                                    title="Terms and Conditions"
                                  >
                                    <GetAppOutlinedIcon
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Link>
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </div>

                    <div class="tab-pane" id="tabItem5">
                      {this.state.call_logs !== "" ? (
                        <>{this.state.call_logs}</>
                      ) : null}
                    </div>

                    <div class="tab-pane" id="tabItem6">
                      {this.state.cilent_comm_popup !== "" ? (
                        <>{this.state.cilent_comm_popup}</>
                      ) : null}
                    </div>
                    <div class="tab-pane" id="tabItem7">
                      {this.state.payment_popup !== "" ? (
                        <>{this.state.payment_popup}</>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* DETAIL MODAL End */}
          {/* SETTINGS MODAL END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Create Subpoena
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              onClick={this.modalAddSubpoena}
                              style={{
                                color: "#fff",
                              }}
                              className="btn btn-primary"
                            >
                              <em class="icon ni ni-plus-round"></em>
                              <span>Add New Request</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      <DataTable
                        columns={this.state.columns}
                        tableData={this.state.tableData}
                      />
                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
