import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";

import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSubmit = (e) => {
    e.preventDefault();
    let { email, password } = this.state;

    if (email === "") {
      this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "admin/login",
            JSON.stringify({
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            }),
            axiosConfig
          )
          .then((response) => {
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: response.data.errors,
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessage: "",
              });

              Cookies.set(
                "admin_token__",
                CryptoJS.AES.encrypt(
                  response.data.token,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "admin_acc__",
                CryptoJS.AES.encrypt(
                  response.data.data[0]["admin_accountno"],
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "admin_eml__",
                CryptoJS.AES.encrypt(
                  response.data.data[0]["email"],
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "admin_type__",
                CryptoJS.AES.encrypt(
                  response.data.data[0]["type"],
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "admin_name__",
                CryptoJS.AES.encrypt(
                  response.data.data[0]["name"],
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "full_name__",
                CryptoJS.AES.encrypt(
                  response.data.data[0]["full_name"],
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              setTimeout(function () {
                window.location.replace("/admin_dashboard");
              }, 1000);
            }
          });
      } catch (error) {
        console.log("catch : ", error);
        window.location.replace("/error");
      }
    }
  };

  render() {
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    } else {
      return (
        <div className="nk-body npc-crypto ui-clean pg-auth">
          <div className="nk-app-root">
            <div className="nk-split nk-split-page nk-split-md">
              {/* START RIGHT PART HERE       */}
              <div
                className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
                data-content="athPromo"
                data-toggle-screen="lg"
                data-toggle-overlay="true"
              >
                <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                  <div
                    className="slider-init"
                    data-slick='{"dots":true, "arrows":false}'
                  >
                    <div className="slider-item">
                      <div className="nk-feature nk-feature-center">
                        <div className="nk-feature-img">
                          <img className="round" src={PROMO_A} alt="" />
                        </div>
                        <div className="nk-feature-content py-4 p-sm-5">
                          <h4>{COMPANY_NAME}</h4>
                          <p>
                            You can start to create your products easily with
                            its user-friendly design & most completed responsive
                            layout.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END RIGHT PART HERE */}

              {/* START LEFT PART HERE(LOGIN PORTION) */}
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
                <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                  <a
                    href="#"
                    className="toggle btn-white btn btn-icon btn-light"
                    data-target="athPromo"
                  >
                    <em className="icon ni ni-info"></em>
                  </a>
                </div>
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    <a href="/" className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={LOGO}
                        alt="logo-dark"
                      />
                    </a>
                  </div>
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">Admin Sign-In</h5>
                      <div className="nk-block-des">
                        <p>Use your verified email and password.</p>
                        {this.state.errorMessage !== "" ? (
                          <div className="example-alert">
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div className="example-alert">
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <form
                    method="post"
                    className="loginForm"
                    onSubmit={this.formSubmit}
                  >
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Email
                        </label>
                      </div>
                      <input
                        type="email"
                        className={
                          "form-control form-control-lg " +
                          this.state.errorEmail
                        }
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Enter your email address"
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          tabIndex="-1"
                          href="#"
                          className="form-icon form-icon-right passcode-switch"
                          data-target="password"
                        >
                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                        </a>
                        <input
                          type="password"
                          className={
                            "form-control form-control-lg " +
                            this.state.errorPass
                          }
                          id="password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-block"
                        disabled={this.state.disabled}
                        type="submit"
                        style={{ backgroundColor: THEME_COLOR, color: "white" }}
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
                <div className="nk-block nk-auth-footer">
                  <div className="mt-3">
                    <p>
                      &copy; 2022-2023{" "}
                      <a
                        href="https://onboardsoft.com/"
                        style={{ color: "#526484" }}
                      >
                        OnBoardsoft LLC
                      </a>
                      . All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>

              {/* END LEFT PART (LOGIN SCREEN) */}
            </div>
          </div>
        </div>
      );
    }
  }
}
