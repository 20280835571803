import React, { Component, Fragment } from "react";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export class DomainVerification extends Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      errorMessage: "",
      tableLoader: false,
      dnsList: "",
    };
  }

  continue = (e) => {
    e.preventDefault();
    //PROCESS FORM//
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = () => {
    const { values } = this.props;
    alert("This step is under development.");
  };

  verificationResult = () => {
    console.log("dnsList.DNSData.dnsRecords", this.props);
    /*if (dnsList.ErrorMessage) {
      return (
        <div class="callout callout-danger">
          <h4>Error verifying the domain</h4>
          {dnsList.ErrorMessage.msg}
        </div>
      );
    } else if (dnsList.DNSData.dnsRecords) {
      return (
        <>
          {dnsList.DNSData.dnsRecords.map((dns) => (
            <p>{dns.type}</p>
          ))}
        </>
      );
    }*/
  };

  render() {
    const {
      values: { domain_title, domain_name, dnsList, a_ip },
    } = this.props;

    console.log("Verification dnsList: ", dnsList);

    return (
      <>
        <div className="offset-lg-2 col-lg-8">
          <div className="nk-block">
            <div className="card card-bordered card-stretch">
              <div className="card-inner-group" style={{ padding: "40px" }}>
                <h5>Connect your domain</h5>
                {dnsList.ErrorMessage ? (
                  <div class="callout callout-danger">
                    <h4>DNS record not found.</h4>
                    {dnsList.ErrorMessage.msg}
                    <br />
                    <br />
                  </div>
                ) : null}

                {dnsList.DNSData ? (
                  <>
                    {dnsList.DNSData.dnsRecords.map((dns, index) => (
                      <>
                        {dns.type == 1 ? (
                          <>
                            {dns.address == "11.111.11.11" ? (
                              <>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD
                                  </strong>{" "}
                                  (@)
                                  <br />
                                  <br />
                                  Current IP address: {dns.address} <br />
                                  Required IP address: 11.111.11.11
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD entered incorrectly
                                  </strong>{" "}
                                  <br />
                                  Check that you've entered the required values,
                                  and verify your connection again.
                                </p>
                                <p>
                                  <strong style={{ fontWeight: "bold" }}>
                                    A RECORD
                                  </strong>{" "}
                                  (@)
                                  <br />
                                  Current IP address:{" "}
                                  <span style={{ color: "red" }}>
                                    {dns.address}
                                  </span>{" "}
                                  <br />
                                  Required IP address: 11.111.11.11
                                </p>
                                <div class="callout callout-warning">
                                  <h4>Connection Incomplete</h4>
                                  Some providers take few minutes to update
                                  their settings. If you want to verify again,
                                  return one step back and click "Verify
                                  Connection" button , or add your domain and
                                  check back later for connection updates.
                                  <br />
                                  <br />
                                  <button
                                    className="btn btn-lg btn-outline-dark"
                                    onClick={this.submitForm}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Add Domain
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}

                <hr />
                <div className="form-group text-right">
                  <button
                    className="btn btn-lg btn-default  btn-outline-dark"
                    onClick={this.back}
                    style={{ marginRight: "10px" }}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={this.submitForm}
                  >
                    Complete Verification
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DomainVerification;
