import { Link } from "react-router-dom";
export default function SettingsError() {
  return (
    <div>
      <div className="example-alert">
        <div className="alert alert-warning alert-icon">
          <em className="icon ni ni-alert-circle"></em>
          <strong>Your account setup is in process, </strong>
          Our team will get back to you shortly.{" "}
        </div>
      </div>
      <br />
    </div>
  );
}
