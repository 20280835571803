import { React } from "react";
import { Component } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { connect } from "react-redux";
import { addSettingsAdmin } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import {
  getClients,
  getReportsData,
  getReportsCsv,
} from "./../../../config/admin_apis";
import $ from "jquery";
import HelperClass from "./../../../config/helperClass";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MutextField from "@material-ui/core/TextField";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const auth = new HelperClass();

class AdminReports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      start_date: datestring_today,
      end_date: datestring,
      clientData: [],
      reseller_accountno: "",
      downloadMessage: "",
      errorMessage: "",
      downloadData: "",
      company_details: [],
      columnsArray: {
        get_daily_orig_stats: {
          title: "Daily Origination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_did_stats: {
          title: "Daily DID Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "NRC CHARGE" },
            { name: "MRC CHARGE" },
            { name: "E911 CHARGE" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_daily_term_stats: {
          title: "Daily Termination Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_daily_sms_stats: {
          title: "Daily SMS Stats",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_daily_revenue: {
          title: "Account Daily Revenue",
          data: [
            { name: "DAY" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
        get_aggregate_orig_stats: {
          title: "Account Origination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "CALL ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "OUT ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_term_stats: {
          title: "Account Termination Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "ACTUAL DURATION" },
            { name: "BILLED DURATION" },
            { name: "ATTEMPTS" },
            { name: "COMPLETED CALLS" },
            { name: "ASR" },
            { name: "SHORT CALLS" },
            { name: "SDP" },
            { name: "NRF" },
          ],
        },
        get_aggregate_sms_stats: {
          title: "Account SMS Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "INBOUND MESSAGES" },
            { name: "OUTBOUND MESSAGES" },
            { name: "INBOUND ATTEMPTS" },
            { name: "OUTBOUND ATTEMPTS" },
          ],
        },
        get_aggregate_did_stats: {
          title: "Account DID Stats",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "DELETES" },
            { name: "ORDERS" },
          ],
        },
        get_aggregate_revenue_stats: {
          title: "Account Revenue",
          data: [
            { name: "ACCOUNT" },
            { name: "REVENUE" },
            { name: "DID REVENUE" },
            { name: "VOICE REVENUE" },
            { name: "SMS REVENUE" },
            { name: "BILLED DURATION" },
            { name: "COMPLETED CALLS" },
            { name: "MESSAGES" },
            { name: "NRC" },
            { name: "MRC" },
            { name: "E911" },
            { name: "GENERIC CHARGES" },
          ],
        },
        ordered_numbers: {
          title: "Ordered Numbers",
          data: [{ name: "COMPANY" }, { name: "TOTAL DIDs" }],
        },
        deleted_numbers: {
          title: "Deleted Numbers",
          data: [
            { name: "ACCOUNT" },
            { name: "START DATE" },
            { name: "END DATE" },
            { name: "TOTAL DELETED" },
            { name: "TOTAL FAILED" },
            {
              name: "DOWNLOAD FILE",
              options: {
                customBodyRender: (value, tableMeta) => {
                  return (
                    <div>
                      <a
                        onClick={() =>
                          this.showDeleteDetails(tableMeta, "deleted_numbers")
                        }
                        style={{ cursor: "pointer", color: "#006393" }}
                      >
                        Download Files
                      </a>{" "}
                    </div>
                  );
                },
              },
            },
          ],
        },
        current_active_dids: {
          title: "Current Active Numbers",
          data: [
            { name: "ACCOUNT" },
            { name: "ACTIVE NUMBERS" },
            { name: "DELETED NUMBERS" },
            {
              name: "DOWNLOAD FILE",
              options: {
                customBodyRender: (value, tableMeta) => {
                  return (
                    <div>
                      <a
                        onClick={() =>
                          this.showDetails(
                            value,
                            tableMeta,
                            "current_active_dids"
                          )
                        }
                        style={{ cursor: "pointer", color: "#006393" }}
                      >
                        <GetAppOutlinedIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </a>{" "}
                    </div>
                  );
                },
              },
            },
          ],
        },
        current_inventory: {
          title: "Current Inventory Numbers",
          data: [
            { name: "ACCOUNT" },
            { name: "ACTIVE INVENTORY NUMBERS" },
            {
              name: "DOWNLOAD FILE",
              options: {
                customBodyRender: (value, tableMeta) => {
                  return (
                    <div>
                      {" "}
                      <a
                        onClick={() =>
                          this.showDetails(
                            value,
                            tableMeta,
                            "current_inventory"
                          )
                        }
                        disabled={
                          this.props.is_msa_signed === "No" ||
                          this.props.defaultSettings === "missing"
                            ? true
                            : false
                        }
                      >
                        <GetAppOutlinedIcon
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </div>
                  );
                },
              },
            },
          ],
        },
        mou: {
          title: "Minutes Of Use",
          data: [
            { name: "ACCOUNT" },
            { name: "START DATE" },
            { name: "END DATE" },
            { name: "MOU" },
          ],
        },
      },
    };
  }

  async componentDidMount() {
    await this.clientsView();
    this.setState({ tableLoader: true });
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      this.props.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );
    console.log("reportsData.data.data: ", reportsData.data.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      if (reportsData.data.message === "missing_settings") {
        this.setState({
          errorMessage: "Missing Settings: This account is not activated.",
        });
      }
      $("#viewBtn").show();
      $("#btnloader").hide();
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        tableData: reportsData.data.data,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false, errorMessage: "" });
    }
    //$("#viewBtn").show();
    $("#btnloader").hide();
  }

  showDeleteDetails = async (tableMeta, file_type) => {
    //console.log("company_details", JSON.stringify(tableMeta.rowData[5][0]));
    this.setState({ company_details: tableMeta.rowData[5] });

    window.$("#modalDelDetails").modal("show");
  };

  showDetails = async (value, tableMeta, file_type) => {
    let company_id = tableMeta.rowData[2];
    let file_name = "";
    if (file_type == "deleted_numbers") {
      company_id = tableMeta.rowData[5];
    }

    window.$("#modalAlert").modal("show");

    this.setState({ downloadMessage: "in_process" });
    const reportsData = await getReportsCsv(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      company_id,
      this.state.start_date,
      this.state.end_date,
      file_type
    );
    console.log("Download reportsData:", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
      this.setState({ downloadMessage: "error" });
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false, downloadMessage: "error" });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        downloadData: reportsData.data.data,
        downloadMessage: "success",
      });
      //Download File
      var actual_path = file_type + "_" + company_id + ".csv";
      fetch(reportsData.data.data)
        .then((x) => x.blob())
        .then((b) => {
          console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = actual_path;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      //Download File
    } else {
      //window.location.replace("/error");
      this.setState({ downloadMessage: "error" });
    }
  };

  downloadDelFile = async (tableMeta) => {
    //let company_id = tableMeta.rowData[2];
    let file_name = "";
    let file_type = "deleted_numbers";
    let company_id = tableMeta;

    window.$("#modalDelDetails").modal("hide");
    window.$("#modalAlert").modal("show");

    this.setState({ downloadMessage: "in_process" });
    const reportsData = await getReportsCsv(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      company_id,
      this.state.start_date,
      this.state.end_date,
      file_type
    );
    console.log("Download reportsData:", reportsData.data);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
      this.setState({ downloadMessage: "error" });
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ tableLoader: false, downloadMessage: "error" });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        downloadData: reportsData.data.data,
        downloadMessage: "success",
      });
      //Download File
      var actual_path = company_id;
      fetch(reportsData.data.data)
        .then((x) => x.blob())
        .then((b) => {
          console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = actual_path;
          a.click();
          window.URL.revokeObjectURL(url);
        });
      //Download File
    } else {
      //window.location.replace("/error");
      this.setState({ downloadMessage: "error" });
    }
  };

  async componentWillReceiveProps(prevProps, nextProps) {
    this.setState({ tableLoader: true });
    await this.clientsView();
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      prevProps.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({ tableData: reportsData.data.data, tableLoader: false });
    } else {
      //window.location.replace("/error");
    }
  }

  clientsView = async () => {
    const loadSettings = await getClients(
      auth.getAdminAccount(),
      auth.getAdminToken()
    );
    //console.log("loadSettings.data.data: ", loadSettings.data.data);
    if (
      loadSettings.data.status === 403 ||
      loadSettings.data.errors === "authentication missing" ||
      loadSettings.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (loadSettings.data.status === 404) {
    } else if (
      loadSettings.data.status === 200 &&
      loadSettings.data.message === "success"
    ) {
      this.setState({
        clientData: loadSettings.data.data,
        reseller_accountno: loadSettings.data.data[0][1],
      });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  formSave = async () => {
    let { start_date, end_date } = this.state;
    await this.handleStates(start_date, end_date);
    $("#viewBtn").hide();
    $("#btnloader").show();
    //this.setState({ tableLoader: true });
    const reportsData = await getReportsData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      this.state.reseller_accountno,
      this.props.props.match.params.api_url,
      this.state.start_date,
      this.state.end_date,
      "all"
    );
    this.setState({
      errorMessage: "",
      tableData: [],
    });
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      if (reportsData.data.message === "missing_settings") {
        this.setState({
          errorMessage: "Missing Settings: This account is not activated.",
        });
      }
      $("#viewBtn").show();
      $("#btnloader").hide();
      //window.location.replace("/error");
      this.setState({ tableLoader: false });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        tableData: reportsData.data.data,
        tableLoader: false,
        errorMessage: "",
      });
    } else {
      //window.location.replace("/error");
      this.setState({ tableLoader: false, errorMessage: "" });
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
    //this.componentDidMount();
  };

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    //console.log("this.props: ", this.props.props.match);
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      Reports &raquo;{" "}
                      {
                        this.state.columnsArray[
                          this.props.props.match.params.api_url
                        ].title
                      }
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row g-4">
                <div className="col-lg-4 col-xxl-4">
                  <div className="form-group" style={{ marginTop: "16px" }}>
                    <MutextField
                      id="reseller_accountno"
                      name="reseller_accountno"
                      select
                      label="Select Reseller"
                      value={this.state.reseller_accountno}
                      onChange={this.setMarkUp}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                    >
                      {this.state.clientData.map((clients) => (
                        <option value={clients[1]}>
                          {clients[4]} - {clients[2]}
                        </option>
                      ))}
                    </MutextField>
                  </div>
                </div>
                {this.props.props.match.params.api_url !=
                "current_inventory" ? (
                  <>
                    <div className="col-lg-3 col-xxl-3">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="start_date"
                            name="start_date"
                            format="yyyy-MM-dd"
                            margin="normal"
                            label="Select Start Date"
                            value={this.state.start_date}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                              variant: "outlined",
                            }}
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>

                    <div className="col-lg-3 col-xxl-3">
                      <div className="form-group">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="end_date"
                            name="end_date"
                            format="yyyy-MM-dd"
                            margin="normal"
                            label="Select End Date"
                            value={this.state.end_date}
                            onChange={this.handleDateChangeEnd}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="col-lg-2 col-xxl-2 text-right">
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      this.formSave();
                    }}
                    style={{ marginTop: "20px" }}
                    disabled={this.state.disabled}
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    id="btnloader"
                    className="btn btn-lg btn-primary"
                    style={{ marginTop: "20px", display: "none" }}
                    disabled="disabled"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Filtering...</span>
                    </div>
                  </button>
                </div>
              </div>
              {this.state.errorMessage !== "" ? (
                <div className="example-alert" style={{ paddingTop: "15px" }}>
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="nk-block" style={{ marginTop: "15px" }}>
                <div className="card card-bordered card-stretch">
                  <div className="card-inner-group">
                    {this.state.tableLoader === true ? (
                      tableLoader()
                    ) : (
                      <DataTable
                        columns={
                          this.state.columnsArray[
                            this.props.props.match.params.api_url
                          ].data
                        }
                        tableData={this.state.tableData}
                        title={
                          this.state.columnsArray[
                            this.props.props.match.params.api_url
                          ].title
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" tabindex="-1" id="modalAlert">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <a href="#" class="close" data-dismiss="modal">
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="modal-body modal-body-lg text-center">
                <div class="nk-modal">
                  <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-download bg-success"></em>
                  {this.state.downloadMessage == "in_process" ? (
                    <>
                      <h4 class="nk-modal-title" style={{ marginTop: "15px" }}>
                        <button class="btn btn-primary" type="button" disabled>
                          <span
                            class="spinner-grow spinner-grow-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span> Preparing Download! </span>
                        </button>
                      </h4>
                      <div class="nk-modal-text">
                        <div class="caption-text">
                          Do not close the window as your request is in
                          process...
                        </div>
                      </div>
                    </>
                  ) : null}

                  {this.state.downloadMessage == "error" ? (
                    <>
                      <div
                        class="alert alert-pro alert-danger"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="alert-text">
                          <h6>Report Download</h6>
                          <p>
                            There is some error while downloading the report.{" "}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {this.state.downloadMessage == "success" ? (
                    <>
                      <div
                        class="alert alert-pro alert-success"
                        style={{ marginTop: "15px" }}
                      >
                        <div class="alert-text">
                          <h6>Report Download</h6>
                          <p>Your file downloaded successfully.. </p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* LCR MODAL START */}
        <div className="modal fade " tabIndex="-1" id="modalDelDetails">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="modal-title"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  <strong>
                    <em className="icon ni ni-upload"></em> Deleted Files{" "}
                    <sup>
                      <small className="text-soft">
                        {this.state.company_title}
                      </small>
                    </sup>
                  </strong>
                </div>
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
              </div>

              <div
                className="modal-body modal-body-lg text-left"
                style={{ padding: "1.5rem" }}
              >
                {this.state.tableLoader === true ? (
                  tableLoader()
                ) : (
                  <TableContainer component={Paper}>
                    <Table className="" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Date Deleted</TableCell>
                          <TableCell align="left">Total Deleted</TableCell>
                          <TableCell align="left">Total Failed</TableCell>
                          <TableCell align="center">Download File</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.company_details.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row" align="left">
                              {row.date_time}
                            </TableCell>
                            <TableCell align="left">
                              {row.total_imported}
                            </TableCell>
                            <TableCell align="center">
                              {row.total_failed}
                            </TableCell>
                            <TableCell align="center">
                              <a
                                onClick={() =>
                                  this.downloadDelFile(row.file_name)
                                }
                                style={{ cursor: "pointer", color: "#006393" }}
                              >
                                <GetAppOutlinedIcon
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* LCR MODAL End */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addSettingsAdmin: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettingsAdmin: (payload) => dispatch(addSettingsAdmin(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminReports);
