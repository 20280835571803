import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { COMPANY_NAME } from "../../../config/config";

import { listClients } from "./../../../config/api_calls";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";

import {
  listServicesGroups,
  listAssignedServices,
  assignServicesToCompany,
} from "./../../../config/api_calls";
import { addSettings, addMsaInfo } from "../../../actions";
import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";

import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import MutextField from "@material-ui/core/TextField";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsClients,
} from "./../../../config/config";
const auth = new HelperClass();

class Clients extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      value: "",
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      did_service: "",
      tfn_service: "",
      term_service: "",
      sms_service: "",
      ivr_service: "",
      liveagent_service: "",
      serviceData: [],
      serviceAssignedData: [],
      company_id: "",
      company_title: "",
      errorMessage: "",
      successMessage: "",
      disabled: false,
      nrc_did: "",
      mrc_did: "",
      rate_did: "",
      nrc_tfn: "",
      mrc_tfn: "",
      rate_tfn: "",
      rate_term: "",
      rate_ivr: "",
      rate_liveagent: "",
      rate_smsin: 0,
      rate_smsout: 0,
      errorMessage: "",
      extra_details: [],
      emptyService: 0,
      columnServices: [
        { name: "ID" },
        { name: "NAME" },
        { name: "PHONE" },
        { name: "EMAIL" },
        {
          name: "OTHER DETAILS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {" "}
                  <a
                    href="#"
                    onClick={() => this.showDetails(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    <Tooltip title="Other Client Details" placement="left">
                      <VisibilityIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </a>
                </div>
              );
            },
          },
        },
        {
          name: "ASSIGNED SERVICES",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {" "}
                  <a
                    href="#"
                    onClick={() => this.showAssignedServices(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    <Tooltip title="Check assigned services" placement="left">
                      <VisibilityIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </a>
                </div>
              );
            },
          },
        },
        {
          name: "ASSIGN RATES",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  {" "}
                  <a
                    href="#"
                    onClick={() => this.showServices(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                    title="Add/Edit markup rates"
                  >
                    Markup Rates
                  </a>
                </div>
              );
            },
          },
        },
      ],
    };
  }
  async componentDidMount() {
    const clientsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      clientsResponce.data.status === 403 ||
      clientsResponce.data.errors === "authentication missing" ||
      clientsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      clientsResponce.data.status === 404 ||
      clientsResponce.data.note === "missing"
    ) {
      // window.location.replace("/error");
      console.log("settings missing");
      this.setState({
        tableLoader: false,
      });
    } else if (
      clientsResponce.data.status === 200 &&
      clientsResponce.data.message === "success"
    ) {
      this.setState({
        tableData: clientsResponce.data.data,
        tableLoader: false,
      });
    } else {
      window.location.replace("/error");
    }
  }

  showServices = async (value, tableMeta) => {
    const servicesResponce = await listServicesGroups(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      errorMessage: "",
      successMessage: "",
      emptyService: 0,
    });

    window.$("#modalFormDetail").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        errorMessage:
          "Services/rates are not added to your account. Please add proper services/rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        errorMessage: "",
      });
      this.renderServices();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning markup rates.",
        emptyService: 1,
      });
      //window.location.replace("/error");
    }
  };

  showAssignedServices = async (value, tableMeta) => {
    const servicesResponce = await listAssignedServices(
      auth.getAccount(),
      auth.getToken(),
      tableMeta.rowData[0]
    );
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      tableLoader: true,
    });
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);
    window.$("#modalAssignedServices").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceAssignedData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        serviceAssignedData: [],
        tableLoader: false,
      });
    }
  };

  showDetails = async (value, tableMeta) => {
    let extra_details = tableMeta.rowData[4].split("###");
    this.setState({
      company_id: tableMeta.rowData[0],
      company_title: tableMeta.rowData[1],
      extra_details: extra_details,
    });

    console.log("Details: ", tableMeta.rowData[4]);
    window.$("#modalClientDetails").modal("show");
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;
    console.log(name);
    this.setState({
      [name]: value,
    });
  };

  renderServices = () => {
    let service_data = this.state.serviceData;
    console.log(service_data);
    let didArray = [];
    let tfnArray = [];
    let termArray = [];
    let smsArray = [];
    let ivrArray = [];
    let liveagentArray = [];
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][5] == "did") {
        let insideArray1 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        didArray.push(insideArray1);
      }
      if (service_data[i][5] == "tfn") {
        let insideArray2 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        tfnArray.push(insideArray2);
      }
      if (service_data[i][5] == "term") {
        let insideArray3 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        termArray.push(insideArray3);
      }
      if (service_data[i][5] == "ivr") {
        let insideArray5 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        ivrArray.push(insideArray5);
      }
      if (service_data[i][5] == "live_agent") {
        let insideArray6 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
        ];
        liveagentArray.push(insideArray6);
      }
      if (service_data[i][5] == "sms") {
        let insideArray4 = [
          service_data[i][0],
          service_data[i][1],
          service_data[i][2],
          service_data[i][3],
          service_data[i][4],
          service_data[i][5],
          service_data[i][6],
          service_data[i][7],
          service_data[i][8],
          service_data[i][9],
          service_data[i][10],
          service_data[i][11],
        ];
        smsArray.push(insideArray4);
      }
    }
    this.setState({
      didArray: didArray,
      tfnArray: tfnArray,
      termArray: termArray,
      smsArray: smsArray,
      ivrArray: ivrArray,
      liveagentArray: liveagentArray,
    });
  };

  setPriceStates = async (
    did_service,
    tfn_service,
    term_service,
    sms_service,
    ivr_service,
    liveagent_service
  ) => {
    if (did_service != "") {
      let did_array = this.state.didArray;
      for (let i = 0; i < did_array.length; i++) {
        if (did_array[i][0] == did_service) {
          this.setState({
            nrc_did: did_array[i][3],
            mrc_did: did_array[i][4],
            rate_did: did_array[i][6],
          });
        }
      }
    }
    if (tfn_service != "") {
      let tfn_array = this.state.tfnArray;
      for (let j = 0; j < tfn_array.length; j++) {
        if (tfn_array[j][0] == tfn_service) {
          this.setState({
            nrc_tfn: tfn_array[j][3],
            mrc_tfn: tfn_array[j][4],
            rate_tfn: tfn_array[j][6],
          });
        }
      }
    }
    if (term_service != "") {
      let term_array = this.state.termArray;
      for (let k = 0; k < term_array.length; k++) {
        if (term_array[k][0] == term_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_term: term_array[k][6],
          });
        }
      }
    }

    if (ivr_service != "") {
      let ivr_array = this.state.ivrArray;
      for (let k = 0; k < ivr_array.length; k++) {
        if (ivr_array[k][0] == ivr_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_ivr: ivr_array[k][6],
          });
        }
      }
    }

    if (liveagent_service != "") {
      let la_array = this.state.liveagentArray;
      for (let k = 0; k < la_array.length; k++) {
        if (la_array[k][0] == liveagent_service) {
          //console.log("HERE TERM: ", term_array[k][3]);
          this.setState({
            rate_liveagent: la_array[k][6],
          });
        }
      }
    }

    if (sms_service != "") {
      let sms_array = this.state.smsArray;
      for (let k = 0; k < sms_array.length; k++) {
        if (sms_array[k][0] == sms_service) {
          //console.log("HERE TERM: ", sms_array[k][10]);
          this.setState({
            rate_smsin: sms_array[k][10],
            rate_smsout: sms_array[k][11],
          });
        }
      }
    }
  };

  formSave = async () => {
    let {
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service,
    } = this.state;
    await this.setPriceStates(
      did_service,
      tfn_service,
      term_service,
      sms_service,
      ivr_service,
      liveagent_service
    );
    this.setState({ errorMessage: "" });

    this.setState({
      errorMessage: "",
      disabled: true,
    });

    const saveResponce = await assignServicesToCompany(
      auth.getAccount(),
      auth.getToken(),
      did_service,
      tfn_service,
      term_service,
      this.state.company_id,
      this.state.nrc_did,
      this.state.mrc_did,
      this.state.rate_did,
      this.state.nrc_tfn,
      this.state.mrc_tfn,
      this.state.rate_tfn,
      this.state.rate_term,
      sms_service,
      this.state.rate_smsin,
      this.state.rate_smsout,
      ivr_service,
      liveagent_service,
      this.state.rate_ivr,
      this.state.rate_liveagent
    );
    console.log("Assign Result: ", saveResponce.data.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "fail" &&
      saveResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: saveResponce.data.note,
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Services assigned successfully.",
        disabled: false,
      });

      $("#viewBtn").show();
      $("#btnloader").hide();

      setTimeout(function () {
        window.$("#modalFormDetail").modal("hide");
      }, 3000);
      this.componentDidMount();
    } else {
      this.setState({
        errorMessage: "There is some error while assigning the services.",
        successMessage: "",
        disabled: false,
      });
      //window.location.replace("/error");
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/*{this.props.is_msa_signed === "No" ? <MsaWarning /> : null} */}
          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitle name="Clients" />
              {/* PAGE TITLE END */}
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetail">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em class="icon ni ni-grid-add-c"></em> Assign
                          Services To{" "}
                          <sup>
                            <small class="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-left">
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.didArray !== "" ? (
                              <div className="col-md-12 col-lg-12 col-xxl-12">
                                <MutextField
                                  id="did_service"
                                  name="did_service"
                                  select
                                  label="Select DID Service"
                                  value={this.state.did_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the DID service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.didArray.map((didservices) => (
                                    <option value={didservices[0]}>
                                      {didservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.didArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="tfn_service"
                                  name="tfn_service"
                                  select
                                  label="Select TFN Service"
                                  value={this.state.tfn_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the TFN service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.tfnArray.map((tfnservices) => (
                                    <option value={tfnservices[0]}>
                                      {tfnservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.termArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="term_service"
                                  name="term_service"
                                  select
                                  label="Select Termination Rate Service"
                                  value={this.state.term_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the termination rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.termArray.map((termservices) => (
                                    <option value={termservices[0]}>
                                      {termservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.ivrArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="ivr_service"
                                  name="ivr_service"
                                  select
                                  label="Select IVR Rate Service"
                                  value={this.state.ivr_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the IVR rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.ivrArray.map((ivrservices) => (
                                    <option value={ivrservices[0]}>
                                      {ivrservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.liveagentArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="liveagent_service"
                                  name="liveagent_service"
                                  select
                                  label="Select Live Agent Rate Service"
                                  value={this.state.liveagent_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the Live Agent rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.liveagentArray.map(
                                    (liveagentservices) => (
                                      <option value={liveagentservices[0]}>
                                        {liveagentservices[1]}
                                      </option>
                                    )
                                  )}
                                </MutextField>
                              </div>
                            ) : null}
                            {this.state.smsArray !== "" ? (
                              <div
                                className="col-md-12 col-lg-12 col-xxl-12"
                                style={{ "margin-top": "15px" }}
                              >
                                <MutextField
                                  id="sms_service"
                                  name="sms_service"
                                  select
                                  label="Select SMS Rate Service"
                                  value={this.state.sms_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select the SMS rate."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option value=""></option>
                                  {this.state.smsArray.map((smsservices) => (
                                    <option value={smsservices[0]}>
                                      {smsservices[1]}
                                    </option>
                                  ))}
                                </MutextField>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-modal-action  text-right">
                          {this.state.emptyService == 0 ? (
                            <a
                              href="#"
                              className="btn btn-lg btn-mw btn-primary "
                              onClick={() => {
                                this.formSave();
                              }}
                              disabled={this.state.disabled}
                            >
                              Assign Services
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* DETAIL MODAL START */}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAssignedServices"
              >
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em class="icon ni ni-grid-add-c"></em> Assigned
                          Services of{" "}
                          <sup>
                            <small class="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <TableContainer component={Paper}>
                                <Table className="" aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">ID</TableCell>
                                      <TableCell align="left">Title</TableCell>
                                      <TableCell align="center">NRC</TableCell>
                                      <TableCell align="center">MRC</TableCell>
                                      <TableCell align="center">
                                        Per Min.Rate
                                      </TableCell>
                                      <TableCell align="left">Type</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.serviceAssignedData.map(
                                      (row) => (
                                        <TableRow key={row[0]}>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="canter"
                                          >
                                            {row[0]}
                                          </TableCell>
                                          <TableCell align="left">
                                            {row[1]}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${row[2]}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${row[3]}
                                          </TableCell>
                                          <TableCell align="center">
                                            ${row[4]}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            style={{
                                              textTransform: "uppercase",
                                            }}
                                          >
                                            {row[5]}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              {/* DETAIL MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalClientDetails">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em class="icon ni ni-card-view"></em> Extra Details
                          of{" "}
                          <sup>
                            <small class="text-soft">
                              {this.state.company_title}
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.tableLoader === true ? (
                              tableLoader()
                            ) : (
                              <TableContainer component={Paper}>
                                <Table className="" aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left" colSpan="2">
                                        Client Details
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow key="1">
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                      >
                                        ADDRESS
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[0]}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow key="2">
                                      <TableCell align="left">
                                        CITY/STATE/ZIP
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[1]} /{" "}
                                        {this.state.extra_details[2]} /{" "}
                                        {this.state.extra_details[3]}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow key="3">
                                      <TableCell align="left">
                                        COUNTRY
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[4]}
                                      </TableCell>
                                    </TableRow>

                                    <TableRow key="4">
                                      <TableCell align="left">
                                        BILLING NAME / EMAIL / PHONE
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[5]} /{" "}
                                        {this.state.extra_details[6]} /{" "}
                                        {this.state.extra_details[7]}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow key="5">
                                      <TableCell align="left">
                                        BILLING ADDRESS
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[8]}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow key="6">
                                      <TableCell align="left">
                                        BILLING CITY / STATE / ZIP / COUNTRY
                                      </TableCell>
                                      <TableCell align="left">
                                        {this.state.extra_details[9]} /{" "}
                                        {this.state.extra_details[10]} /{" "}
                                        {this.state.extra_details[11]} /{" "}
                                        {this.state.extra_details[12]}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DETAIL MODAL End */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START TABLE HEAD */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title="All Clients"
                        />
                      )}

                      {/* END TABLE HEAD */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
