import { React } from "react";
import { Component } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listDefaultServices,
  addNewService,
  addCsvFile,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class Addservice extends Component {
  constructor() {
    super();
    this.state = {
      serviceData: [],
      select_service: "",
      errorMessage: "",
      serviceId: "",
      title: "",
      service_title: "",
      service_desc: "",
      service_type: "did",
      successMessage: "",
      tableLoader: true,
      nrc_rate_type: "flat",
      min_rate_type: "flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: 0.0,
      new_mrc: 0.0,
      rate_type: "",
      rate: 0.0,
      defaultRate: "",
      product_id: "",
      mrc_rate_type: "flat",
      select_rate_type: "flat_rate",
      rate_deck_file: [],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listDefaultServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });

    let service_data = this.state.serviceData;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: Number.parseFloat(service_data[i][3]).toFixed(4),
          defaultMrc: Number.parseFloat(service_data[i][4]).toFixed(4),
          rate_type: service_data[i][5],
          defaultRate: Number.parseFloat(service_data[i][5]).toFixed(4),
          service_title: service_data[i][1],
          service_desc: service_data[i][2],
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type: service_data[i][9],
        });
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  sendFileData = async (filedata) => {
    /*var formData = new FormData();
    var imagefile = filedata;
    formData.append("file", imagefile);
    formData.append("token", process.env.REACT_APP_API_TOKEN);
    formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
    formData.append("accountno", auth.getAccount());*/
    const saveResponces = await addCsvFile(auth.getAccount(), auth.getToken());
    console.log("rate_deck_file: ", saveResponces);
    /*axios
      .post(
        process.env.REACT_APP_API_URL + "uploads/list_pages",
        JSON.stringify({
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          accountno: auth.getAccount(),
        }),
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Credentials": true,
            useCredentails: true,
          },
        }
      )
      .then((response) => {
        //resolve(response);
        console.log("CSV response : ", response);
      });*/
  };

  formSave = async (e) => {
    e.preventDefault();
    let {
      select_service,
      service_type,
      new_nrc,
      new_mrc,
      nrc_rate_type,
      mrc_rate_type,
      min_rate_type,
      defaultNrc,
      defaultMrc,
      rate,
      defaultRate,
      defaultSmsIn,
      defaultSmsOut,
      min_sms_in_type,
      min_sms_out_type,
      sms_in,
      sms_out,
      rate_type,
      title,
    } = this.state;

    var is_validated = true;

    if (select_service === "") {
      is_validated = false;
    } else if (title === "") {
      is_validated = false;
    } else if (service_type === "did" || service_type === "tfn") {
      if (new_nrc === "") {
        is_validated = false;
      }
      if (new_mrc === "") {
        is_validated = false;
      }
      if (rate === "") {
        is_validated = false;
      }
    } else if (service_type === "term") {
      if (rate === "") {
        is_validated = false;
      }
    } else if (service_type === "ivr") {
      if (rate === "") {
        is_validated = false;
      }
    } else if (service_type === "live_agent") {
      if (rate === "") {
        is_validated = false;
      }
    } else if (service_type === "sms") {
      if (sms_in === "") {
        is_validated = false;
      }
      if (sms_out === "") {
        is_validated = false;
      }
    }

    if (is_validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({ errorMessage: "" });

      if (nrc_rate_type === "percentage") {
        new_nrc = this.calculatePercent(new_nrc, defaultNrc);
      }
      if (mrc_rate_type === "percentage") {
        new_mrc = this.calculatePercent(new_mrc, defaultMrc);
      }
      if (min_rate_type === "percentage") {
        rate = this.calculatePercent(rate, defaultRate);
      }
      if (min_sms_in_type === "percentage") {
        sms_in = this.calculatePercent(sms_in, defaultSmsIn);
      }
      if (min_sms_out_type === "percentage") {
        sms_out = this.calculatePercent(sms_out, defaultSmsOut);
      }
      if (service_type == "term") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (service_type == "ivr") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (service_type == "live_agent") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (service_type == "did") {
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (service_type == "tfn") {
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (service_type == "sms") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        defaultRate = "";
        rate = "";
      }

      this.setState({
        errorMessage: "",
        //disabled: true,
      });

      const saveResponce = await addNewService(
        auth.getAccount(),
        auth.getToken(),
        new_nrc,
        new_mrc,
        rate,
        this.state.product_id,
        select_service,
        service_type,
        title,
        this.state.service_title,
        this.state.service_desc,
        nrc_rate_type,
        mrc_rate_type,
        this.state.min_sms_in_type,
        this.state.min_sms_out_type,
        sms_in,
        sms_out
      );

      console.log("ADD SERVICE RESPONSE: ", saveResponce);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        return this.setState({
          errorMessage: "There is some error while adding the service.",
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        window.$("#modalAlert").modal("show");

        this.setState({
          successMessage: "Congratulations!",
        });
        this.setState({
          errorMessage: "",
          successMessage: "Rates Saved Successfully.",
          disabled: false,
        });

        $("#viewBtn").show();
        $("#btnloader").hide();

        setTimeout(function () {
          window.$("#modalAlert").modal("hide");
          //window.location.replace("/services");
        }, 3000);
        this.componentDidMount();
      } else {
        return this.setState({
          errorMessage: "There is some error while adding the services.",
        });
        //window.location.replace("/error");
      }
    }
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {/*{this.props.is_msa_signed === "No" ? <MsaWarning /> : null}*/}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/services")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Services
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Services
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div class="card-head">
                    <h5 class="card-title">Add your new service here.</h5>
                  </div>
                  {this.state.errorMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div className="example-alert">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Add New Service</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.successMessage}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                href="#"
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <form
                    method="post"
                    className="serviceForm"
                    onSubmit={this.formSave}
                    id="serviceForm"
                  >
                    <div className="row g-4">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <MutextField
                            id="select_service"
                            name="select_service"
                            select
                            label="Select Service"
                            value={this.state.select_service}
                            onChange={this.setMarkUp}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please select the service from default services."
                            variant="outlined"
                            fullWidth
                          >
                            <option key="" value=""></option>
                            {this.state.serviceData.map((services) => (
                              <option value={services[0]}>{services[1]}</option>
                            ))}
                          </MutextField>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            required
                            id="title"
                            name="title"
                            type="text"
                            label="Service Title"
                            defaultValue={this.state.title}
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <MutextField
                            id="service_type"
                            name="service_type"
                            select
                            label="Select Service Type"
                            defaultValue={this.state.service_type}
                            onChange={this.handleChange}
                            SelectProps={{
                              native: true,
                            }}
                            helperText="Please select the service type e.g DID, TollFree or termination."
                            variant="outlined"
                            fullWidth
                          >
                            <option key="did" value="did">
                              DID
                            </option>
                            <option key="tfn" value="tfn">
                              Toll Free
                            </option>
                            <option key="term" value="term">
                              Termination
                            </option>
                            <option key="term" value="live_agent">
                              Live Agent
                            </option>
                            <option key="term" value="ivr">
                              IVR
                            </option>
                            <option key="sms" value="sms">
                              SMS
                            </option>
                          </MutextField>
                        </div>
                      </div>

                      {this.state.service_type !== "term" &&
                      this.state.service_type !== "sms" &&
                      this.state.service_type !== "ivr" &&
                      this.state.service_type !== "live_agent" ? (
                        <>
                          <div className="col-lg-12">
                            <h6>MRC Settings</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="defaultMrc"
                                label="Default MRC"
                                value={this.state.defaultMrc}
                                variant="outlined"
                                disabled
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="mrc_rate_type"
                                name="mrc_rate_type"
                                select
                                label="Select Rate Type"
                                defaultValue={this.state.mrc_rate_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Rate Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="flat" value="flat">
                                  Flat
                                </option>
                                <option key="percentage" value="percentage">
                                  Percentage
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="new_mrc"
                                name="new_mrc"
                                type="text"
                                label="Updated MRC"
                                defaultValue={this.state.new_mrc}
                                onChange={this.handleChange}
                                variant="outlined"
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <h6>NRC Settings</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="defaultNrc"
                                label="Default NRC"
                                value={this.state.defaultNrc}
                                variant="outlined"
                                fullWidth
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="nrc_rate_type"
                                name="nrc_rate_type"
                                select
                                label="Select Rate Type"
                                value={this.state.nrc_rate_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Rate Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="flat" value="flat">
                                  Flat
                                </option>
                                <option key="percentage" value="percentage">
                                  Percentage
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="new_nrc"
                                name="new_nrc"
                                type="text"
                                label="Updated NRC"
                                defaultValue={this.state.new_nrc}
                                onChange={this.handleChange}
                                variant="outlined"
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                fullWidth
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      {this.state.service_type !== "sms" ? (
                        <>
                          <div className="col-lg-12">
                            <h6>Rate Per Minute Settings</h6>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                disabled
                                id="defaultRate"
                                name="defaultRate"
                                label="Default Rate Per Min"
                                value={this.state.defaultRate}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="min_rate_type"
                                name="min_rate_type"
                                select
                                label="Select Rate Type"
                                value={this.state.min_rate_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Rate Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="flat" value="flat">
                                  Flat
                                </option>
                                <option key="percentage" value="percentage">
                                  Percentage
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="rate"
                                name="rate"
                                label="Rate"
                                defaultValue={this.state.rate}
                                onChange={this.handleChange}
                                variant="outlined"
                                type="text"
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                fullWidth
                              />
                            </div>
                          </div>
                        </>
                      ) : null}

                      {this.state.service_type == "sms" ? (
                        <>
                          <div className="col-lg-12">
                            <h6>SMS Inbound Settings</h6>
                          </div>

                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                disabled
                                id="defaultSmsIn"
                                name="defaultSmsIn"
                                label="Default SMS Inbound Rate"
                                value={this.state.defaultSmsIn}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="min_sms_in_type"
                                name="min_sms_in_type"
                                select
                                label="Select Rate Type"
                                value={this.state.min_sms_in_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Rate Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="flat" value="flat">
                                  Flat
                                </option>
                                <option key="percentage" value="percentage">
                                  Percentage
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="sms_in"
                                name="sms_in"
                                label="SMS Inbound Rate"
                                defaultValue={this.state.sms_in}
                                onChange={this.handleChange}
                                variant="outlined"
                                type="text"
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                fullWidth
                              />
                            </div>
                          </div>

                          <br />
                          <div className="col-lg-12">
                            <h6>SMS Outbound Settings</h6>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                disabled
                                id="defaultSmsOut"
                                name="defaultSmsOut"
                                label="Default SMS Outbound Rate"
                                value={this.state.defaultSmsOut}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="min_sms_out_type"
                                name="min_sms_out_type"
                                select
                                label="Select Rate Type"
                                value={this.state.min_sms_out_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Rate Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="flat" value="flat">
                                  Flat
                                </option>
                                <option key="percentage" value="percentage">
                                  Percentage
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <MutextField
                                id="sms_out"
                                name="sms_out"
                                label="SMS Outbound Rate"
                                defaultValue={this.state.sms_out}
                                onChange={this.handleChange}
                                variant="outlined"
                                type="text"
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                fullWidth
                              />
                            </div>
                          </div>
                        </>
                      ) : null}

                      <div className="col-lg-12">
                        <div className="form-group text-right">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            disabled={this.state.disabled}
                          >
                            Save Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addservice);
